img {
    max-width: 100%;
    border-radius: $border-radius-sm;
}
.img-raised {
    box-shadow: $box-shadow-raised;
}
.elementCard {
    display: flex;
    justify-content: center;
}

.img-select {
    width: 100%;
    height: 100%;
    padding: 2rem;

}