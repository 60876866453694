.icon {
	width: $icon-size;
	height: $icon-size;

	i, svg {
		font-size: $icon-size - .75;
	}
}

.icon + .icon-text {
	padding-left: 1rem;
	width: calc(100% - #{$icon-size} - 1);
}

.icon-xl {
	width: $icon-size-xl;
	height: $icon-size-xl;

	i, svg {
		font-size: $icon-size-xl - .75;
	}
}

.icon-xl + .icon-text {
	width: calc(100% - #{$icon-size-xl} - 1);
}

.icon-lg {
	width: $icon-size-lg;
	height: $icon-size-lg;

	i, svg {
		font-size: $icon-size-lg - .75;
	}
}

.icon-lg + .icon-text {
	// width: calc(100% - #{$icon-size-lg} - 1);
}

.icon-sm {
	width: $icon-size-sm;
	height: $icon-size-sm;

	i, svg {
		font-size: $icon-size-sm - .75;
	}
}

.icon-sm + .icon-text {
	// width: calc(100% - #{$icon-size-sm} - 1);
}


// Icons included in shapes
.icon-shape {
	padding: 12px;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;


	i, svg {
		font-size: 1.25rem;
	}

	&.icon-lg {
		i, svg {
			font-size: 1.625rem;
		}
	}

	&.icon-sm {
		i, svg {
			font-size: .875rem;
		}
	}

	svg {
		width: 30px;
		height: 30px;
	}

}

.tooltip-icon-1 {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip-icon-1 .icon-component-1 {
	visibility: hidden;
	position: absolute;
	z-index: 1;
	font-family: Arial, Helvetica, sans-serif;
	bottom: 33%;
	right: 0;
	margin-right: -110px;
	opacity: 0;
	transition: opacity 0.3s;
}

  /* Show the tooltip text when you mouse over the tooltip container */
.tooltip-icon-1:hover .icon-component-1 {
	visibility: visible;
	opacity: 1;
}

.tooltip-icon-2 {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip-icon-2 .icon-component-2 {
	visibility: hidden;
	position: absolute;
	font-weight: normal;
	z-index: 1;
	font-family: Arial, Helvetica, sans-serif;
	bottom: 33%;
	right: 0;
	margin-right: -110px;
	opacity: 0;
	transition: opacity 0.3s;
}

  /* Show the tooltip text when you mouse over the tooltip container */
.tooltip-icon-2:hover .icon-component-2 {
	visibility: visible;
	opacity: 1;
}

.tooltip-icon-3 {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip-icon-3 .icon-component-3 {
	visibility: hidden;
	position: absolute;
	font-weight: normal;
	z-index: 1;
	font-family: Arial, Helvetica, sans-serif;
	bottom: 33%;
	right: 0;
	margin-right: -110px;
	opacity: 0;
	transition: opacity 0.3s;
}

  /* Show the tooltip text when you mouse over the tooltip container */
.tooltip-icon-3:hover .icon-component-3 {
	visibility: visible;
	opacity: 1;
}


@each $color, $value in $theme-colors {
	.icon-shape-#{$color} {
		@include icon-shape-variant(theme-color($color));
	}
}
