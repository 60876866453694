div.orgChart {
  font-family: sans-serif;
  border: 1px solid transparent;
  background-color: transparent;
  padding: 10px;
  overflow-y: auto;
  overflow-x: auto;
}

div.orgChart h2 {
  margin: 0px;
  font-size: 1em;
  border-bottom: none;
}

div.orgChart ul {
  list-style: none;
  margin: 4px;
  padding: 0px;
  font-size: 0.8em;
  text-align: left;
}

div.orgChart ul.stack,
div.orgChart ul.stack ul {
  text-align: center;
}

div.orgChart table {
  width: 100%;
}

div.orgChart tr.lines td.line {
  width: 1px;
  height: 20px;
}

div.orgChart tr.lines td.top {
  border-top: 3px solid #efefef;
}

div.orgChart tr.lines td.left {
  border-right: 2px solid #efefef;
}

div.orgChart tr.lines td.right {
  border-left: 2px solid #efefef;
}

div.orgChart td {
  text-align: center;
  vertical-align: top;
  padding: 0px 2px;
}


div.orgChart > table > tbody tr > td > div.hasChildren, 
div.orgChart > table > tbody tr > td > div.teste,
div.orgChart div.node  {
  cursor: default;
  border: 2px solid black;
  display: inline-block;
  padding: 0 4px;
  min-width: 160px;
  white-space: pre-line;
  min-height: 60px;
  color: white;
  background-color: #27293D;
  font-size: 13px;
  line-height: 1.3em;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}


div.orgChart .stack .node {
  cursor: default;
}

div.orgChart div.hasChildren {
  background-color: transparent;
  max-width: 110px;
}

div.orgChart.interactive div.hasChildren {
  cursor: pointer;
}

div.orgChart div.node.hover {
  background-color: white;
}

/* Adjunct width is 110, the link is 20, so the left margin must be adjusted -(110+20) */

div.orgChart div.adjunct.node {
  position: absolute;
  margin-top: 8px;
  margin-left: -130px;
  width: 110px;
  height: 40px;
  background-color: #efefef;
  
}

div.orgChart div.adjunct-link {
  display: inline-block;
  position: absolute;
  margin-left: -20px;
  margin-top: 25px;
  width: 20px;
  border: 1px dashed black;
}
